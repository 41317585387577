import { authHeader, handleResponse } from '../helpers';
export const itemsService = {
    getReseachData,
    globalItemSearch,
    getReseachDataFromCsv,
    getListingLists,
    getLastSearchedData,
    getPredictions,
    getPastSearches,
    getPartHighlights
};
function getReseachData(ApiCallSessionID, LaborCost = 2 , PlatformCommission = 0.15, MinProfit = 0.25) { 
    const requestOptions = { 
        method: 'GET', 
        headers: authHeader() 
    }; 
    const url = `${process.env.REACT_APP_API}/PartsResearch/GetResearchedData?ApiSessionID=${ApiCallSessionID}&LaborCost=${LaborCost}&PlatformCommission=${PlatformCommission}&MinProfit=${MinProfit}`; 
    return fetch(url, requestOptions) 
    .then(handleResponse); 
}
function getLastSearchedData() {
    const requestOptions = { 
        method: 'GET', 
        headers: authHeader()
    };
    return fetch(process.env.REACT_APP_API + "/PartsResearch/GetLastSearchedData", requestOptions)
    .then(handleResponse);
}
function globalItemSearch(PartNumber, DaysToSearch, Condition) {
    const requestOptions = { 
        method: 'GET', 
        headers: authHeader()
    };
    return fetch(process.env.REACT_APP_API +"/PartsResearch/searchData?PartNumber=" + PartNumber 
        + "&DaysToSearch=" + DaysToSearch
        + "&Condition=" + Condition
        , requestOptions)
    .then(handleResponse);
}
function getReseachDataFromCsv(csvFile) {
    const requestOptions = { 
        method: 'POST', 
        //headers: { 'Content-Type': 'application/json' },
        body: csvFile
    };
    return fetch(process.env.REACT_APP_API +"/PartsResearch/searchDatafromCsv", requestOptions)
    .then(handleResponse);
}
function getListingLists(TeraPeakTabsSummaryID, TabType) {
    const requestOptions = { 
        method: 'GET', 
        headers: authHeader()
    };
    return fetch(process.env.REACT_APP_API +"/PartsResearch/GetListingLists?TeraPeakTabsSummaryID=" + TeraPeakTabsSummaryID + "&TabType=" + TabType, requestOptions)
    .then(handleResponse);
}
function getPredictions(apiCallSessionID) {
    const requestOptions = { 
        method: 'GET', 
        headers: authHeader()
    };
    return fetch(process.env.REACT_APP_API +"/PartsResearch/GetPredictions?ApiCallSessionID=" + apiCallSessionID, requestOptions)
    .then(handleResponse);
}
function getPastSearches() {
    const requestOptions = { 
        method: 'GET', 
        headers: authHeader()
    };
    return fetch(process.env.REACT_APP_API +"/PartsResearch/GetPastSearchesList", requestOptions)
    .then(handleResponse);
}
function getPartHighlights(teraPeakTabsSummaryID) {
    const requestOptions = { 
        method: 'GET', 
        headers: authHeader()
    };
    return fetch(process.env.REACT_APP_API +"/PartsResearch/GetPartHighlights?TeraPeakTabsSummaryID=" + teraPeakTabsSummaryID, requestOptions)
    .then(handleResponse);
}