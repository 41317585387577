import React,{useState, useEffect } from 'react';
import { Card, Container, Form, Button } from 'react-bootstrap';
import { algoPixService } from '../services';
import Loader from './Loader';
export default function AgloPixFilesComponent ({apiCallSessionID}) {
    const [isLoading, setIsLoading] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    useEffect(() => {
        if (apiCallSessionID) {
        }
      }, [apiCallSessionID]);

    const downloadCsvForAlopix = async () => {
      if(apiCallSessionID){
        setIsLoading(true);
        try {
                const blob = await algoPixService.downloadCsvForAlopix(apiCallSessionID);
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'parts list for algopix.csv');
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            } 
            catch (error) {
                console.error(error);
            }
            finally {
                setIsLoading(false);
        }
      }
    };
    const handleFileChange = (e) => {
      const file = e.target.files[0];
      if (file) {
          // Validate file type
          const fileType = file.name.split('.').pop().toLowerCase();
          if (fileType !== 'csv') {
              alert('Please select a CSV file.');
              return;
          }
          setSelectedFile(file);
      }
    };

    const uploadAlgopixFile = () => {
      if(apiCallSessionID){
        setIsLoading(true);
        //setData([]);
        if (selectedFile) {
            const formData = new FormData();
            formData.append('file', selectedFile);
            algoPixService.uploadAlgopixDatafromCsv(formData, apiCallSessionID)
            .then((data) => {
                if(data){
                }
            })
            .catch(error => {
                console.error('Error uploading file:', error);
                alert(error);
            }).finally(final => {
                setIsLoading(false);               
            });
        }
        else{
            alert('Please select a file to upload');
            setIsLoading(false);
        }
      }
    };
return (
    <div>    
        {isLoading? <Loader /> : 
        <Container style={{paddingTop: "10px"}}>
          <Card className="shadow-card">
            <Card.Body>
              <Form>
                <h5 className="text-center" id="pageHeading">AlgoPix Research</h5>
                <div style={{ display: 'flex', alignItems: 'top', paddingTop:'10px' }}>
                  <div>
                    <Button variant="primary" onClick={downloadCsvForAlopix} style={{ height: '40px', marginRight:'10px' }}>Download parts data for Algopix research</Button>
                    <br />
                    <br />
                    <a href="https://algopix.com/xapp/multi" target="_blank" rel="noopener noreferrer">Open Algopix research tool</a>
                  </div>
                  <div style={{ marginRight: '10px', paddingRight: '10px' }}>
                                          <Form.Control id='searchFile' type="file" accept=".csv" onChange={handleFileChange} />
                                          <Button variant="primary"  onClick={uploadAlgopixFile} style={{ marginTop: '10px', marginBottom: '10px' }}>
                                              Upload Algopix data file
                                          </Button>
                  </div>
                </div>
              </Form>
            </Card.Body>
            </Card>
        </Container>}
    </div>
    )
}