export const constants = {
 appDateFormate: "DD/MM/YYYY",
 appDateFormateWithDayName: "ddd, DD/MM/YY",
 timeWithAMPM: "hh:mm A",
 passwordsDoNotMatch: "Passwords do not match",
 gridPageSizeOptions: [10, 50, 75, 100, 1000, 5000 ],
 gridPageSize:50,
 currentMonth: (new Date().getMonth()) + 1,
 currentYear: (new Date().getFullYear())
}

export const columnDescriptionData = [
    { column: 'Sell-through Rate', description: 'Is a measurement of how fast items are selling. For example, a sell-through rate of 500% means that on average each listing has gained 5 sales. The higher the sell-through %, the better!' },
    { column: 'Depreciation', description: 'This is depreciation despription' },
];