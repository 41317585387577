import React, { useState, useEffect } from 'react';
import { Card, Container, Form, Button } from 'react-bootstrap';
import { MainMenu } from './MainMenu';
import Footer from './Footer.js';
import Loader from './Loader';
import { itemsService, downloadSamplePartsList, setupsService } from '../services';
import MaterialTable from '@material-table/core';
import { ExportCsv, ExportPdf } from '@material-table/exporters';
import { tableIcons, constants, columnDescriptionData } from '../helpers';
import Modal from 'react-bootstrap/Modal';
import ListingLists  from './ListingLists.js';
import { Link } from 'react-router-dom';
import InventoryIcon from '@mui/icons-material/Inventory';
import 'bootstrap/dist/css/bootstrap.min.css';
import PredictionComponent from './PredictionComponent.js';
import { useLocation } from 'react-router-dom';
import AgloPixFilesComponent from './AgloPixFilesComponent.js';
import { processColumnColor } from '../helpers/tableDataHandler.js';
const exportFileName = "research-data".concat(" ", new Date().getDate(), new Date().getMonth());

export default function ResearchData() {
    const [pageData, setData] = useState([]);
    const [conditionsList, setConditionData] = useState([]);
    const [durationList, setDurationData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [selectedTeraPeakTabsSummaryID] = useState('');
    const [selectedtabType] = useState('');
    const [ApiCallSessionID, setApiCallSessionID] = useState(null);
    const handleCloseModal = () => setShowDetailsModal(false);
    const queryParam = new URLSearchParams(useLocation().search);
    var PartsSearchID = queryParam.get('psid');
    const [isModalOpen, setModalOpen] = useState(false);
    const [defaultCalErrors, setDefaultCalErrors] = useState({});
    const [searchInputs, setSearchInputs] = useState({
        partNumber: '',
        condition: '0',
        duration: '7',
        laborCost: '2',
        platformCommission: '0.15',
        minProfit: '0.25'
    });

    useEffect(() => {
        setIsLoading(true);
        Promise.all([
            setupsService.getConditionsList(true, ''),
            setupsService.getDurationList(true, ''),
        ])
            .then(([conditionsData, durationData]) => {
                setConditionData(conditionsData);
                setDurationData(durationData);
            if(PartsSearchID){
                    itemsService.getReseachData(PartsSearchID)
                        .then((data2) => {
                        if(data2){
                                setData(data2);
                                setApiCallSessionID(PartsSearchID);
                                setIsLoading(false);
                            }
                        })
                }
            else{
                    setIsLoading(false);
                }
            })
            .catch(error => {
                console.error('Error fetching setup data:', error);
                alert(error);
                setIsLoading(false);
            });
    }, [PartsSearchID]);

    const handleSingleSearch = () => {
        setIsLoading(true);
        setData([]);
        if (searchInputs.partNumber) {
            itemsService.globalItemSearch(searchInputs.partNumber
                , searchInputs.duration
                , searchInputs.condition)
                .then((data) => {
                    itemsService.getReseachData(data.apiSessionID, searchInputs.laborCost, searchInputs.platformCommission, searchInputs.minProfit)
                        .then((data2) => {
                            if (data2) {
                                setData(data2);
                                setApiCallSessionID(data.apiSessionID);
                            }
                        })
                })
                .catch(error => {
                    console.error('Error Searching for part: ', error);
                    alert(error);
                }).finally(final => {
                    setIsLoading(false);
                });
        }
        else {
            alert('Please enter part number to search');
            setIsLoading(false);
        }
    };
    const handleModalOpen = () => {
        setModalOpen(true);
    };
    const handleModalClose = () => {
        setModalOpen(false);
    };

    const handleMultiSearch = () => {
        setIsLoading(true);
        setData([]);
        if (selectedFile) {
            const formData = new FormData();
            formData.append('file', selectedFile);
            itemsService.getReseachDataFromCsv(formData)
                .then((data) => {
                    if (data) {
                        itemsService.getReseachData(data.data.apiSessionID, searchInputs.laborCost, searchInputs.platformCommission, searchInputs.minProfit)
                            .then((data2) => {
                                if (data2) {
                                    setData(data2);
                                    setApiCallSessionID(data.data.apiSessionID);
                                }
                            })
                    }
                })
                .catch(error => {
                    console.error('Error uploading file:', error);
                    alert(error);
                }).finally(final => {
                    setIsLoading(false);
                });
        }
        else {
            alert('Please select a file to upload');
            setIsLoading(false);
        }
    };

    const loadLastSearched = () => {
        setIsLoading(true);
        setData([]);
        itemsService.getLastSearchedData()
            .then((data) => {
                if (data) {
                    setData(data);
                    setApiCallSessionID(data[0].hd_ApiCallSessionID);
                }
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error loading last data', error);
                alert(error);
                setIsLoading(false);
            });
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            // Validate file type
            const fileType = file.name.split('.').pop().toLowerCase();
            if (fileType !== 'csv') {
                alert('Please select a CSV file.');
                return;
            }
            setSelectedFile(file);
        }
    };

    // Function to generate dynamic columns based on data keys
    const generateColumns = (data) => {
        if (data.length === 0) {
            return [];
        }
        const keys = Object.keys(data[0]);
        const filteredKeys = keys.filter(key => !key.startsWith('hd_') && key !== 'SonicInvDetails');
        return filteredKeys.map((key) => {
            let displayKey = key;
            let color = null;
            const checkColor = processColumnColor(key);
            if (checkColor != null) {
                displayKey = checkColor.column;
                color = checkColor.color;
            }
            return {
                title: displayKey,
                field: key,
                hidden: key === 'TeraPeakTabsSummaryID',
                render: (rowData) => {
                    if (key === 'Image') {
                        return <img src={rowData[key]} alt="Thumbnail" style={{ width: 100, height: 100 }} />;
                    }
                    else if (key === 'Part Number') {
                        return (
                            <Link to={rowData['hd_PartEbay_Link']} target="_blank" rel="noopener noreferrer">
                                {rowData[key]}
                            </Link>
                        );
                    }
                    return rowData[key];
                },
                cellStyle: (key === 'Part Number' || key === 'Search Condition' || key === 'Commudity' || key === 'Quantity')
                    ? { backgroundColor: 'lightgrey' }
                    : (color != null) ? { backgroundColor: color } : {}
            }
        });
    };

    const downloadSamplePartsList2 = async () => {
        setIsLoading(true);
        try {
            const blob = await downloadSamplePartsList();
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'sample_ebay_parts_list.csv');
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        }
        catch (error) {
            console.error(error);
        }
        finally {
            setIsLoading(false);
        }
    };

    const openConditionsPopup = () => {
        const popupContent = `
          <ul>
            <li>new</li>
            <li>new other</li>
            <li>new with defects</li>
            <li>certified refurbished</li>
            <li>seller refurbished</li>
            <li>like new</li>
            <li>used</li>
            <li>very good</li>
            <li>good</li>
            <li>acceptable</li>
            <li>not working</li>
            <li>all</li>
          </ul>`;
        // Open popup
        const popupWindow = window.open('', 'popupWindow', 'width=400,height=400');
        popupWindow.document.write(popupContent);
        popupWindow.document.title = 'Conditions';
    };

    const clearFields = () => {
        setSearchInputs({ partNumber: '', condition: '0', duration: '7' });
        setSelectedFile(null);
        document.getElementById('searchFile').value = null;
    };

    const ShowListingDeetails = (id, type) => {
        return `/LisingList/${id}?part=${type}`;
    };

    const applyFilter = () => {
        if(Object.keys(defaultCalErrors).length > 0){
            return
        }
        setIsLoading(true);
        setData([]);
        itemsService.getReseachData(ApiCallSessionID, searchInputs.laborCost, searchInputs.platformCommission, searchInputs.minProfit)
            .then((data) => {
                if (data) {
                    setData(data);
                    setIsLoading(false);
                }
            })
    }

    const validateCalField = (name, value) => {
        const newErrors = { ...defaultCalErrors};
        if (!value) {
            newErrors[name] = `${name.charAt(0).toUpperCase() + name.slice(1)} is required`;
        } else {
            delete newErrors[name];
        }
        setDefaultCalErrors(newErrors);
    };
    
    return (
        <>
            <MainMenu />
            <div className="fullScreen">
                <h1 className="text-center" id="pageHeading">Parts Research</h1>
                <Container>
                    <Card className="shadow-lg p-3 bg-white rounded">
                        <Card.Body>
                            <Container>
                                <Card className="shadow-card">
                                    <Card.Body>
                                        <Form>
                                            <Form.Group style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                                <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                                    <div style={{ marginRight: '10px', paddingRight: '10px', borderRight: '1px solid #D3D3D3' }}>
                                                        <Form.Label>Upload a File (CSV)</Form.Label>
                                                        <Form.Control id='searchFile' type="file" accept=".csv" onChange={handleFileChange} />
                                                        <Button variant="primary" onClick={handleMultiSearch} style={{ marginTop: '10px', marginBottom: '10px' }}>
                                                            Search Multiple Items
                                                        </Button>
                                                    </div>
                                                    <div style={{ marginLeft: '10px', flexGrow: 1, display: 'flex', flexDirection: 'column', width: '100%' }}>
                                                        <div style={{ display: 'flex', width: '100%' }}>
                                                            <div style={{ flex: 1, marginRight: '10px' }}>
                                                                <Form.Label>Single Part Search</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Enter text"
                                                                    value={searchInputs.partNumber}
                                                                    onChange={(e) =>
                                                                        setSearchInputs((prev) => ({ ...prev, partNumber: e.target.value }))
                                                                    }
                                                                />
                                                                <Button variant="primary" onClick={handleSingleSearch} style={{ marginTop: '10px', marginBottom: '10px', width: '100%' }}>
                                                                    Search Single Item
                                                                </Button>
                                                            </div>
                                                            <div style={{ flex: 1, marginLeft: '10px' }}>
                                                                <Form.Label>Condition</Form.Label>
                                                                <Form.Control
                                                                    as="select"
                                                                    value={searchInputs.condition}
                                                                    onChange={(e) =>
                                                                        setSearchInputs((prev) => ({ ...prev, condition: e.target.value }))
                                                                    }
                                                                    style={{ appearance: 'auto' }} // Ensure default browser appearance
                                                                >
                                                                    {conditionsList.map((condition) => (
                                                                        <option key={condition.EbayConditionCode} value={condition.EbayConditionCode}>
                                                                            {condition.ConditionName}
                                                                        </option>
                                                                    ))}
                                                                </Form.Control>
                                                            </div>
                                                            <div style={{ flex: 1, marginLeft: '10px' }}>
                                                                <Form.Label>Duration</Form.Label>
                                                                <Form.Control
                                                                    as="select"
                                                                    value={searchInputs.duration}
                                                                    onChange={(e) =>
                                                                        setSearchInputs((prev) => ({ ...prev, duration: e.target.value }))
                                                                    }
                                                                    style={{ appearance: 'auto' }} // Ensure default browser appearance
                                                                >
                                                                    {durationList.map((duration) => (
                                                                        <option key={duration.DurationDays} value={duration.DurationDays}>
                                                                            {duration.DurationName}
                                                                        </option>
                                                                    ))}
                                                                </Form.Control>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Form.Group>

                                            <div style={{ borderTop: '1px solid #D3D3D3', height: '30px' }}></div>
                                            <Button variant="primary" onClick={loadLastSearched} style={{ marginTop: '10px', marginBottom: '10px' }}>
                                                Load Last Searched Data
                                            </Button>
                                            <Button variant="primary" onClick={downloadSamplePartsList2} style={{ marginLeft: '10px' }}>
                                                Download Sample File
                                            </Button>
                                            <Button variant="secondary" onClick={clearFields} style={{ marginLeft: '10px' }}>
                                                Clear
                                            </Button>
                                            <a href="#popup" style={{ marginLeft: '10px' }} onClick={openConditionsPopup}>Acceptable Conditions</a>
                                        </Form>
                                    </Card.Body>
                                </Card>
                            </Container>
                            {isLoading ? (<Loader message="Please wait..." />) : (
                                ApiCallSessionID ? (<AgloPixFilesComponent apiCallSessionID={ApiCallSessionID} />) : null
                            )}
                            {isLoading ? (<Loader message="Please wait..." />) : (
                                ApiCallSessionID ? (
                                    <Container style={{ paddingTop: "10px" }}>
                                        <Card className="shadow-card">
                                            <Card.Body>
                                                <h5 className="text-center">Default Calculations</h5>
                                                <Form>
                                                    <Form.Group style={{ display: "flex", justifyContent: "space-around", flexWrap: "wrap", width: "100%", height:"115px" }}>
                                                        <div style={{ flex: "1 1 200px", margin: "10px" }}>
                                                            <Form.Label>Labor Cost</Form.Label>
                                                            <Form.Control
                                                                type="number"
                                                                value={searchInputs.laborCost}
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    setSearchInputs((prev) => ({ ...prev, laborCost: value }));
                                                                    validateCalField('laborCost', value);
                                                                }}
                                                                isInvalid={!!defaultCalErrors.laborCost}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {defaultCalErrors.laborCost}
                                                            </Form.Control.Feedback>
                                                        </div>
                                                        <div style={{ flex: "1 1 200px", margin: "10px" }}>
                                                            <Form.Label>Platform Commission</Form.Label>
                                                            <Form.Control
                                                                type="number"
                                                                value={searchInputs.platformCommission}
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    setSearchInputs((prev) => ({ ...prev, platformCommission: value }));
                                                                    validateCalField('platformCommission', value);
                                                                }}
                                                                isInvalid={!!defaultCalErrors.platformCommission}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {defaultCalErrors.platformCommission}
                                                            </Form.Control.Feedback>
                                                        </div>
                                                        <div style={{ flex: "1 1 200px", margin: "10px" }}>
                                                            <Form.Label>Min Profit</Form.Label>
                                                            <Form.Control
                                                                type="number"
                                                                value={searchInputs.minProfit}
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    setSearchInputs((prev) => ({ ...prev, minProfit: value }));
                                                                    validateCalField('minProfit', value);
                                                                }}
                                                                isInvalid={!!defaultCalErrors.minProfit}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {defaultCalErrors.minProfit}
                                                            </Form.Control.Feedback>
                                                        </div>
                                                        <div style={{ flex: "1 1 200px", margin: "10px", display: "flex", alignItems: "center", paddingTop: "8px" }}>
                                                            <Button
                                                                variant="primary"
                                                                style={{ width: "100%" }}
                                                                onClick={applyFilter}
                                                                disabled = {!!Object.keys(defaultCalErrors).length}
                                                            >
                                                                Apply
                                                            </Button>
                                                        </div>
                                                    </Form.Group>
                                                </Form>


                                            </Card.Body>
                                        </Card>
                                    </Container>
                                ) : null
                            )}

                            {isLoading ? (<Loader message="Please wait..." />) : (
                                ApiCallSessionID ? (<PredictionComponent apiCallSessionID={ApiCallSessionID} />) : null
                            )}
                            {isLoading ? (<Loader message="Please wait..." />) : (
                                <Container>
                                    <MaterialTable
                                        style={{ width: '100%', marginTop: '20px' }}
                                        title={
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <Button
                                                    variant="contained"
                                                    onClick={handleModalOpen}
                                                    style={{ fontSize: "14px", color: "#0d6efd" }}
                                                >
                                                    Columns Description
                                                </Button>
                                            </div>
                                        }
                                        columns={generateColumns(pageData)} // Dynamic columns
                                        data={pageData}
                                        icons={tableIcons}
                                        actions={[
                                            {
                                                icon: InventoryIcon,
                                                tooltip: 'View Details',
                                                onClick: (event, rowData) => {
                                                    const url = ShowListingDeetails(rowData['TeraPeakTabsSummaryID'], rowData['Part Number']);
                                                    window.open(url, '_blank', 'noopener,noreferrer');
                                                }
                                            }
                                        ]}
                                        options={{
                                            pageSizeOptions: constants.gridPageSizeOptions,
                                            pageSize: constants.gridPageSize,
                                            emptyRowsWhenPaging: false,
                                            paginationType: 'normal',
                                            showFirstLastPageButtons: false,
                                            sorting: true,
                                            search: true,
                                            doubleHorizontalScroll: true,
                                            headerStyle: {
                                                backgroundColor: '#ff4500',
                                                color: '#FFF',
                                                whiteSpace: 'nowrap',
                                                position: 'sticky', // Sticky positioning
                                                top: 0, // Stick to the top of the table container
                                                zIndex: 1, // Ensure it stays above the table body
                                                fontSize: '12px'
                                            },
                                            rowStyle: {
                                                fontSize: '12px'
                                            },
                                            //maxBodyHeight: '200px', // Adjust this to your desired table height
                                            exportMenu: [
                                                {
                                                    label: 'Export PDF',
                                                    exportFunc: (cols, datas) => ExportPdf(cols, datas, exportFileName)
                                                }, {
                                                    label: 'Export CSV',
                                                    exportFunc: (cols, datas) => ExportCsv(cols, datas, exportFileName)
                                                }]
                                        }}
                                    />
                                </Container>
                            )}
                        </Card.Body>
                    </Card>
                </Container>

                <Modal
                    show={showDetailsModal}
                    onHide={handleCloseModal}
                    backdrop="static"
                    keyboard={false}
                    size='xl'
                    dialogClassName="full-screen-modal" // Custom class for full-screen modal
                >
                    <Modal.Body><ListingLists teraPeakTabsSummaryID={selectedTeraPeakTabsSummaryID} tabType={selectedtabType} /></Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModal}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/* Modal for Columns Descriptions */}
                <Modal
                    show={isModalOpen}
                    onHide={handleModalClose}
                    size='xl'
                    dialogClassName="full-screen-modal"
                >
                    <Modal.Body>
                        <h5 style={{
                            textAlign: "center",
                            padding: "16px",
                            fontWeight: "bold",
                            fontSize: "1.25rem",
                            marginBottom: "20px"
                        }}>
                            Column Descriptions
                        </h5>
                        <table style={{ width: "100%", borderCollapse: "collapse", tableLayout: "auto", border: "1px solid #ddd" }}>
                            <thead>
                                <tr>
                                    <th
                                        style={{
                                            textAlign: "center",
                                            padding: "8px",
                                            width: "1%",
                                            whiteSpace: "nowrap",
                                            borderRight: "1px solid #ddd",
                                            borderBottom: "1px solid #ddd",
                                        }}
                                    >
                                        Column
                                    </th>
                                    <th
                                        style={{
                                            textAlign: "center",
                                            padding: "8px",
                                            borderRight: "1px solid #ddd",
                                            borderBottom: "1px solid #ddd",
                                        }}
                                    >
                                        Description
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {columnDescriptionData.map((data, index) => (
                                    <tr key={index}>
                                        <td
                                            style={{
                                                padding: "8px",
                                                borderBottom: "1px solid #ddd",
                                                borderRight: "1px solid #ddd",
                                                whiteSpace: "nowrap",
                                            }}
                                        >
                                            {data.column}
                                        </td>
                                        <td
                                            style={{
                                                padding: "8px",
                                                borderBottom: "1px solid #ddd",
                                                borderRight: "1px solid #ddd",
                                            }}
                                        >
                                            {data.description}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleModalClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
            <Footer />
        </>
    );
};