import React,{useState, useEffect } from 'react';
import { Card, Container, Row, Col } from 'react-bootstrap';
import { itemsService } from '../services';
import Loader from './Loader';
export default function PredictionComponent ({apiCallSessionID}) {
    const [isLoading, setIsLoading] = useState(false);
    const [pageData, setData] = useState([]);
    useEffect(() => {
        if (apiCallSessionID) {
        setIsLoading(true);
        itemsService.getPredictions(apiCallSessionID)
          .then(data => {
            setData(data);
            setIsLoading(false);
          })
          .catch((error) => {
            console.error('Error fetching data:', error);
            setIsLoading(false);
          });
        }
      }, [apiCallSessionID]); // <-- Have to pass in [] here!

  return (
    <div>
      {isLoading? <Loader /> :
        <Container style={{paddingTop: "10px"}}>
          <Card className="shadow-card">
            <Card.Body>
              <Row className="d-flex flex-row flex-nowrap overflow-auto">
                {Object.keys(pageData).map((key, index) => (
                  <Col>
                    <Card>
                      <Card.Body>
                        <Card.Title className="fs-6">{key}</Card.Title>
                        <Card.Text className="fs-6">{pageData[key]}</Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Card.Body>
          </Card>
        </Container>
      }
    </div>
  )
}